import useWalletContext from "@/providers/wallet"
import { useMemo } from "react"

export const useIsWalletExist = () => {
  const { state } = useWalletContext()

  const isWalletExist = useMemo(() => {
    if(state.wallets.length > 0) return true;
  },[state.wallets])
  
  return !!isWalletExist;
}
