import { useReducer } from 'react'
import initialState from './state'
import { reducer } from './reducer'
import useHttpClient from '../http-client'
import { EActions, IWithdrawBody, IWithdrawEstimateBody } from './types'

export const useReferralsService = () => {
  const [state, dispatch] = useReducer(reducer, initialState())
  const httpClient = useHttpClient()

  const getReferrals = async () => {
    const { data } = await httpClient.get('api/marketing')
    dispatch({ type: EActions.GET_REFERRALS, referrals: data.data })
  }

  const updateWithdraw = (body: IWithdrawEstimateBody) => {
    dispatch({ type: EActions.UPDATE_WITHDRAW, withdraw: body })
  }

  const withdrawEstimate = async (body: IWithdrawEstimateBody) => {
    const { data } = await httpClient.post('/api/marketing/withdrawal/estimate', body)
    return data.data
  }

  const withdraw = async (body: IWithdrawBody) => {
    const { data } = await httpClient.post('api/marketing/withdrawal', body)
    return data
  }

  return {
    state,
    getReferrals,
    updateWithdraw,
    withdrawEstimate,
    withdraw
  }
}
