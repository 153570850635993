import { EStatus } from '@/constants/common/status'
import { WalletState } from './types'

const initialState = (): WalletState => ({
  wallets: [],
  merchant: {},
  getStatus: EStatus.INIT,
  preSendPath: '',
  currencies: []
})

export default initialState
