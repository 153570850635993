import { EStatus } from '@/constants/common/status'
import { ISwapHistoryState } from './types'

const initialState = (): ISwapHistoryState => ({
  transactions: [],
  pagination: {
    page: 1,
    pages: 0,
    countItems: 0
  },
  status: EStatus.INIT,
  selectedTransactionId: null
})

export default initialState
