import { Actions, EActions, NetworksState } from './types'

export const reducer = (state: NetworksState, action: Actions) => {
  switch (action.type) {
    case EActions.GET_NETWORKS: {
      return {
        ...state,
        networks: action.networks
      }
    }
    case EActions.SET_SELECTED_NETWORKS: {
      return {
        ...state,
        selectedNetworks: action.selectedNetworks
      }
    }
  }
}
