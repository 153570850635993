import { IAssetBalanceItem, IAssetItem } from '@/types/api/balance/assets.interface'
import { ICurrency } from '@/types/api/swap/currency.interface'

export const parseAssets = (
  assets: IAssetBalanceItem[],
  currencies: ICurrency[]
): IAssetBalanceItem[] => {
  const assetsArr = assets
    .map((item: IAssetBalanceItem) => ({
      ...item,
      assets: item.assets.filter((asset: IAssetItem) =>
        currencies.some((currency: any) => currency._id === asset.id && currency.supportSwap)
      )
    }))
    .filter((item: IAssetBalanceItem) => item.assets.length > 0)
    .sort((a: IAssetBalanceItem, b: IAssetBalanceItem) => (a.balanceUsd > b.balanceUsd ? -1 : 1))

  return assetsArr
}
