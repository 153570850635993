import { ISwapState } from "./types"

const initialState = (): ISwapState => ({
  assets: [],
  assetForSend: null,
  assetForReceive: null,
  sendAmount: null,
  estimates: [],
  offer: null,
  error: null,
  swapData: null
})

export default initialState