export enum EActions {
  GET_SEED_PHRASE = 'getSeedPhrase'
}

export interface BackUpState {
  seedPhrase: string[]
}
export interface IBackUpContext {
  state: BackUpState
  getUserSeedPhrase: (id: string) => Promise<any>
}

export interface IActionGetSeedPhrase {
  type: EActions.GET_SEED_PHRASE
  seedPhrase: Array<string>
}

export type Actions = IActionGetSeedPhrase
