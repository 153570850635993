import { ComponentType, createContext, PropsWithChildren } from 'react'
import initialState from './state'
import { useSwapHistoryService } from './service'
import { ISwapHistoryContext } from './types'
import { ISwapHistoryParams } from '@/types/api/swap/swap.interface'

export const SwapHistoryContext = createContext<ISwapHistoryContext>({
  state: initialState(),
  getHistory: async (merchantId: string, params: ISwapHistoryParams) => {},
  setSelectedTransaction: (transactionId: string) => {}
})

export const SwapHistoryProvider: ComponentType<PropsWithChildren> = ({ children }) => {
  const service = useSwapHistoryService()

  return <SwapHistoryContext.Provider value={service}>{children}</SwapHistoryContext.Provider>
}
