import { ComponentType, createContext, PropsWithChildren } from 'react'

import { IAuthContext } from './types'
import { useAuthService } from './use-auth-service'

export const AuthContext = createContext<IAuthContext>({
  token: null,
  authorize: () => {}
})

const AuthProvider: ComponentType<PropsWithChildren> = ({ children }) => {
  const service = useAuthService()

  return <AuthContext.Provider value={service}>{children}</AuthContext.Provider>
}

export default AuthProvider
