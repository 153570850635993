import { useReducer } from 'react'
import reducer from './reducer'
import initialState from './state'
import { EActions } from './types'
import useHttpClient from '../http-client'
import { requestGetSeedPhrase } from './api/request-get-seed'

export const useBackUpService = () => {
  const [state, dispatch] = useReducer(reducer, initialState())
  const httpClient = useHttpClient()

  const getUserSeedPhrase = async (merchantId:string) => {
    try {
      const { data } = await requestGetSeedPhrase(httpClient, merchantId)
      dispatch({ type: EActions.GET_SEED_PHRASE, seedPhrase: data.data.split(" ") })
      return data
    } catch (error) {
      throw error
    }
  }

  return {
    state,
    getUserSeedPhrase
  }
}
