import { ISwapHistoryItem } from '@/types/api/swap/swap.interface'
import { ISwapTransactionModel } from '../types'

export const parseTransactions = (history: ISwapHistoryItem[]): ISwapTransactionModel[] => {
  return history.map((item: ISwapHistoryItem) => {
    return {
      fromCurrency: item.from.currency.name,
      toCurrency: item.to.currency.name,
      fromCurrencyTitle: item.from.currency.title,
      toCurrencyTitle: item.to.currency.title,
      toAccount: item.to.address,
      fromAccount: item.from.address,
      networkFee: item.from.minerFee.value.toString(),
      networkName: '',
      networkSymbol: '',
      fromNetwork: item.from.currency.blockChain,
      toNetwork: item.to.currency.blockChain,
      hash: item.to.hash,
      date: item.updatedAt,
      status: item.systemStatus,
      partner: item.partner,
      exchangeRate: item.convertRate.toString(),
      sentAmount: item.from.amount.value.toString(),
      receivedAmount: item.to.amount.value.toString(),
      confirmationBlock: '',
      id: item.id,
      explorerUrl: item.to.explorerUrl,
      networkFeeCurrency: item.from?.minerFee?.currency || ''
    }
  })
}
