import { ReferralsState } from './types'

const initialState = (): ReferralsState => ({
  referrals: {
    refId: '',
    earn: 0,
    minWithdrawal: 0,
    refs: 0
  },
  withdraw: {
    amount: 0,
    to: '',
    currency: ''
  }
})

export default initialState
