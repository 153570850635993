import { Actions, EActions, ReferralsState } from './types'

export const reducer = (state: ReferralsState, action: Actions) => {
  switch (action.type) {
    case EActions.GET_REFERRALS: {
      return {
        ...state,
        referrals: action.referrals
      }
    }
    case EActions.UPDATE_WITHDRAW: {
      return {
        ...state,
        withdraw: action.withdraw
      }
    }
  }
}
