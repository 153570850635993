import { useReducer } from 'react'
import initialState from './state'
import { reducer } from './reducer'
import { EActions, INetwork, INetworksResponse } from './types'
import useHttpClient from '../http-client'
import { IApiResponse } from "@/types/api/api.interface"

export const useNetworkService = () => {
  const [state, dispatch] = useReducer(reducer, initialState())
  const httpClient = useHttpClient()

  const getNetworks = async (): Promise<any> => {
    const { data } = await httpClient.get<IApiResponse<INetworksResponse>>('api/currency/main')
    const networks = data.data.currencies as INetwork[];

    dispatch({ type: EActions.GET_NETWORKS, networks })
    dispatch({ type: EActions.SET_SELECTED_NETWORKS, selectedNetworks: [networks[0]._id] })
  }

  const setSelectedNetworks = (ids: string[]): void => {
    dispatch({ type: EActions.SET_SELECTED_NETWORKS, selectedNetworks: ids })
  }

  return {
    state,
    getNetworks,
    setSelectedNetworks
  }
}
