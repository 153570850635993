import { Actions, EActions, ImportState } from './types'

export const reducer = (state: ImportState, action: Actions) => {
  switch (action.type) {
    case EActions.UPDATE_IMPORT: {
      return {
        ...state,
        import: action.import
      }
    }
  }
}
