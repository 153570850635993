import { Actions, EActions, SettingsState } from './types'

export const reducer = (state: SettingsState, action: Actions) => {
  switch (action.type) {
    case EActions.GET_PROFILE: {
      return {
        ...state,
        profile: action.profile
      }
    }
    case EActions.GET_TWO_FACTOR: {
      return {
        ...state,
        twoFactor: action.twoFactor
      }
    }
    case EActions.SET_NEW_EMAIL_ON_CHANGE: {
      return {
        ...state,
        newEmail: action.newEmail
      }
    }
  }
}
