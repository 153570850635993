import { IReducerAction } from '@/types/other/state.interface'

export interface IWithdrawal {
  id: string
  type: string
  typeNetwork: string
  status: boolean
  systemStatus: string
  merchant: string
  user: string
  info: {
    currency: string
    nodeType: string
    currencyType: string
    blockchainName: string
    incomingTxHash: string
    currencyId: string
    exchangeId: number
    explorerUrl: {
      current: string
      incoming: string
    }
    from: string
    to: string
    fromId: string
    toId: string
    hashs: string[]
    addresses: string[]
    multisend: {
      address: string
      value: string
      usd: string
    }[]
    call: {
      contractAddress: string
      method: string
      options: any
      value: string
    }
    nft: {
      contractAddress: string
      tokenId: string
      name: string
      symbol: string
      type: string
    }
    amount: {
      value: number
      usd: number
    }
    minerFee: {
      value: number
      usd: number
      currency: string
    }
    systemFee: {
      value: number
      usd: number
      currency: string
      hash: string
    }
    fees: any
    steps: {
      merchantWallet: {
        hash: string
        from: string
        to: string
        amount: {
          value: number
          usd: number
        }
        minerFee: {
          value: number
          usd: number
        }
      }
      middleWallet: {
        hash: string
        from: string
        to: string
        amount: {
          value: number
          usd: number
        }
        minerFee: {
          value: number
          usd: number
        }
      }
    }
  }
  errorMessage: string
  createdAt: string
  updatedAt: string
}

export interface IWithdrawalsResponse {
  page: number
  pages: number
  countItems: number
  entities: IWithdrawal[]
}

export interface IWithdrawalBody {
  password?: string
  sign?: string
  to?: string
  amount?: string
  priority?: string
  currencyToken?: string
  payerFeePrivateKey?: string
  comment?: string
  twoFactorToken?: string
  emailOtp?: string
  verifyCode?: string
}

export interface IWithdrawalFee {
  fee: string
  minerFee: string
  systemFee: string
  merchantFee: string
  currencyMinerFee: string
  currencyFee: string
  currencyMinerMinSystemFee: string
  isPayerFee: boolean
}

export interface IWithdrawalFeeBody {
  password?: string
  sign?: string
  to?: string
  amount?: string
  priority?: string
  currencyToken?: string
  payerFeePrivateKey?: string
}

export interface IEstimateMaxResponse {
  amount: string
}

export interface IEstimateMaxBody {
  password?: string
  sign?: string
  to?: string
  currencyToken?: string
  payerFeePrivateKey?: string
  priority?: string
  isSwap?: boolean
}

export interface WithdrawalState {
  withdrawals: IWithdrawal[]
  fee: IWithdrawalFee | null
  estimateMax: string
  sendUsdValue: string
  sendCryptoValue: string
  sendWalletAddress: string
  sendScreenState: string
  sendTonComment: string
  sendTransactionSpeed: string
  sendToggle: boolean
}
export interface IWithdrawalContext {
  state: WithdrawalState
  getWithdrawals: () => Promise<any>
  createWithdrawal: (merchantId: string, walletId: string, body: IWithdrawalBody) => Promise<any>
  getFee: (merchantId: string, walletId: string, body: IWithdrawalBody) => Promise<any>
  getEstimateMax: (merchantId: string, walletId: string, body: IEstimateMaxBody) => Promise<any>
  setUsdValue: (value: string) => void
  setCryptoValue: (value: string) => void
  setWalletAddress: (address: string) => void
  setTonComment: (comment: string) => void
  setTransactionSpeed: (speed: string) => void
  setToggle: (toggle: boolean) => void
  resetEstimateMax: () => void
  updateSendScreenState: (state: string) => void
}

export enum EActions {
  GET_WITHDRAWALS = 'GET_WITHDRAWALS',
  GET_FEE = 'GET_FEE',
  GET_ESTIMATE_MAX = 'GET_ESTIMATE_MAX',
  SET_USD_VALUE = 'SET_USD_VALUE',
  SET_CRYPTO_VALUE = 'SET_CRYPTO_VALUE',
  SET_WALLET_ADDRESS = 'SET_WALLET_ADDRESS',
  SET_TOGGLE = 'SET_TOGGLE',
  SET_SCREEN_STATE = 'SET_SCREEN_STATE',
  SET_TON_COMMENT = 'SET_TON_COMMENT',
  SET_TRANSACTION_SPEED = 'SET_TRANSACTION_SPEED'
}
export interface IWithdrawalsActionGet extends IReducerAction<EActions.GET_WITHDRAWALS> {
  withdrawals: any
}
export interface IFeeActionGet extends IReducerAction<EActions.GET_FEE> {
  fee: any
}
export interface IEstimateMaxActionGet extends IReducerAction<EActions.GET_ESTIMATE_MAX> {
  estimateMax: any
}
export interface IUpdateUsdValue extends IReducerAction<EActions.SET_USD_VALUE> {
  sendUsdValue: string
}
export interface IUpdateCryptoValue extends IReducerAction<EActions.SET_CRYPTO_VALUE> {
  sendCryptoValue: string
}
export interface IUpdateWalletAddress extends IReducerAction<EActions.SET_WALLET_ADDRESS> {
  sendWalletAddress: string
}
export interface IUpdateToggle extends IReducerAction<EActions.SET_TOGGLE> {
  sendToggle: boolean
}
export interface IUpdateSendScreenState extends IReducerAction<EActions.SET_SCREEN_STATE> {
  sendScreenState: string
}
export interface IUpdateSendCommentTON extends IReducerAction<EActions.SET_TON_COMMENT> {
  sendTonComment: string
}
export interface IUpdateTransactionSpeed extends IReducerAction<EActions.SET_TRANSACTION_SPEED> {
  sendTransactionSpeed: string
}
export type Actions =
  | IWithdrawalsActionGet
  | IFeeActionGet
  | IEstimateMaxActionGet
  | IUpdateUsdValue
  | IUpdateCryptoValue
  | IUpdateWalletAddress
  | IUpdateToggle
  | IUpdateSendScreenState
  | IUpdateSendCommentTON
  | IUpdateTransactionSpeed
