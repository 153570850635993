import { ImportState } from './types'

const initialState = (): ImportState => ({
  import: {
    key: '',
    isMnemonic: false,
    name:'',
    currencies: [],
    onlyValidate: true
  }
})

export default initialState
