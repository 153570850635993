import { ToastContainerProps } from 'react-toastify'

export enum EToastType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info'
}
export enum EToasCustomClass {
  COPIED = 'toast-copied'
}

export const TOAST_CONFIG: ToastContainerProps = {
  position: 'top-center',
  autoClose: 5000,
  closeButton: false,
  hideProgressBar: true,
  closeOnClick: true
}
