import React, { createContext, useContext, ComponentType, PropsWithChildren } from 'react'

import { type IBackUpContext } from './types'
import initialState from './state'
import { useBackUpService } from './service'

const BackupContext = createContext<IBackUpContext>({
  state: initialState(),
  getUserSeedPhrase: async (merchantId:string) => { }
})

export const BackupProvider: ComponentType<PropsWithChildren> = ({ children }) => {
  const service = useBackUpService()
  return <BackupContext.Provider value={service}>{children}</BackupContext.Provider>
}
const useBackupContext = () => useContext(BackupContext)

export default useBackupContext
