import { ComponentType, createContext, PropsWithChildren } from 'react'

import initialState from './state'
import { useReferralsService } from './service'
import { IReferralsContext } from './types'

export const ReferralsContext = createContext<IReferralsContext>({
  state: initialState(),
  getReferrals: async () => {},
  withdrawEstimate: async () => {},
  withdraw: async () => {},
  updateWithdraw: () => {}
})

export const ReferralsProvider: ComponentType<PropsWithChildren> = ({ children }) => {
  const service = useReferralsService()
  return <ReferralsContext.Provider value={service}>{children}</ReferralsContext.Provider>
}
