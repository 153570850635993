import axios from 'axios'

export function getHttpClient() {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'x-api-key': process.env.REACT_APP_SECRET_HEADER_KEY
    }
  })

  return instance
}
