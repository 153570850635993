import { EStatus } from '@/constants/common/status'
import { useIsWalletExist } from '@/providers/wallet/hooks/use-is-wallet-exist'
import { useRequestWalletStatus } from '@/providers/wallet/hooks/use-request-wallet-status'
import { useEffect, useState, ReactNode, ComponentType } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

interface IProps {
  redirectPath?: string
  children: ReactNode
}

const WalletGuard: ComponentType<IProps> = ({
  redirectPath = '/initial/hero',
  children
}) => {
  const [isAllowed, setIsAllowed] = useState<boolean | null>(null)
  const isWalletExist = useIsWalletExist()
  const walletStatus = useRequestWalletStatus()

  useEffect(() => {
    if (walletStatus !== EStatus.SUCCESS) return

    setIsAllowed(isWalletExist)
  }, [isWalletExist, walletStatus])

  if (isAllowed === null) {
    return <div></div>
  }

  return isAllowed ? <>{children || <Outlet />}</> : <Navigate to={redirectPath} />
}

export default WalletGuard
