import { Actions, EActions, WithdrawalState } from './types'

export const reducer = (state: WithdrawalState, action: Actions) => {
  switch (action.type) {
    case EActions.GET_WITHDRAWALS: {
      return {
        ...state,
        withdrawals: action.withdrawals
      }
    }
    case EActions.GET_FEE: {
      return {
        ...state,
        fee: action.fee
      }
    }
    case EActions.SET_USD_VALUE: {
      return {
        ...state,
        sendUsdValue: action.sendUsdValue
      }
    }
    case EActions.SET_CRYPTO_VALUE: {
      return {
        ...state,
        sendCryptoValue: action.sendCryptoValue
      }
    }
    case EActions.SET_SCREEN_STATE: {
      return {
        ...state,
        sendScreenState: action.sendScreenState
      }
    }
    case EActions.SET_TON_COMMENT: {
      return {
        ...state,
        sendTonComment: action.sendTonComment
      }
    }
    case EActions.SET_TRANSACTION_SPEED: {
      return {
        ...state,
        sendTransactionSpeed: action.sendTransactionSpeed
      }
    }
    case EActions.SET_TOGGLE: {
      return {
        ...state,
        sendToggle: action.sendToggle
      }
    }
    case EActions.SET_WALLET_ADDRESS: {
      return {
        ...state,
        sendWalletAddress: action.sendWalletAddress
      }
    }
    case EActions.GET_ESTIMATE_MAX: {
      return {
        ...state,
        estimateMax: action.estimateMax
      }
    }
  }
}
