import { createContext } from 'react'
import { IStorageContext } from './types'

const value: IStorageContext = {
  getItem(key: string) {
    return localStorage.getItem(key)
  },
  setItem(key: string, value: any) {
    localStorage.setItem(key, value)
  },
  getItemJSON(key: string) {
    const value = localStorage.getItem(key)

    if (!value) {
      return null
    }

    return JSON.parse(value)
  },
  setItemJSON(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  removeItem(key: string) {
    localStorage.removeItem(key)
  }
}

export const StorageContext = createContext<IStorageContext>(value)
