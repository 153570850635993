import { ComponentType, createContext, PropsWithChildren } from 'react'
import { ISwapContext, ISwapAssetModel, ISwapOfferModel, ISwapParams, IEstimateMaxParams } from './types'
import initialState from './state'
import { useSwapService } from './service'
import { IAssetBalanceItem } from '@/types/api/balance/assets.interface'
import { ICurrency } from '@/types/api/swap/currency.interface'
import { IEstimateMaxBody } from '@/types/api/withdrawal/withdrawal.interface'

export const SwapContext = createContext<ISwapContext>({
  state: initialState(),
  getAssets: async (assets: IAssetBalanceItem[], currencies: ICurrency[]) => {},
  setAssetForSend: (asset: ISwapAssetModel) => {},
  setAssetForReceive: (asset: ISwapAssetModel) => {},
  replaceAssets: () => {},
  setSendAmount: (value: string) => {},
  estimateSwap: async () => {},
  setOffer: (offer: ISwapOfferModel) => {},
  swap: async (params: ISwapParams) => {},
  estimateMax: async (params: IEstimateMaxParams, body: IEstimateMaxBody) => {},
  resetState: () => {}
})

export const SwapProvider: ComponentType<PropsWithChildren> = ({ children }) => {
  const service = useSwapService()

  return <SwapContext.Provider value={service}>{children}</SwapContext.Provider>
}
