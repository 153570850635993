import { EStatus } from '@/constants/common/status'
import { Actions, EActions, IBalanceState } from './types'

export const reducer = (state: IBalanceState, action: Actions) => {
  switch (action.type) {
    case EActions.HIDE_BALANCE: {
      return {
        ...state,
        isHidden: true
      }
    }
    case EActions.SHOW_BALANCE: {
      return {
        ...state,
        isHidden: false
      }
    }
    case EActions.GET: {
      return {
        ...state,
        getStatus: EStatus.PROCESSING
      }
    }
    case EActions.GET_SUCCESS: {
      return {
        ...state,
        getStatus: EStatus.SUCCESS,
        balance: action.payload
      }
    }
    case EActions.GET_CURRENCIES: {
      return {
        ...state,
        getStatus: EStatus.SUCCESS,
        currencies: action.payload
      }
    }
    case EActions.SET_ASSET_HISTORY: {
      return {
        ...state,
        getStatus: EStatus.SUCCESS,
        assetTransactions: action.transactions
      }
    }
    case EActions.GET_ERROR: {
      return {
        ...state,
        getStatus: EStatus.ERROR
      }
    }
    case EActions.GET_ASSETS: {
      return {
        ...state,
        assets: action.payload
      }
    }
    default: {
      return state
    }
  }
}
