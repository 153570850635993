import { IReducerAction } from '@/types/other/state.interface'

export enum ESettingModalType {
  EMAIL = 'email',
  AUTH_APP = 'authApp',
  PIN_CODE = 'pinCode'
}
export type SettingModalType = 'email' | 'authApp' | 'pinCode'

export interface IProfile {
  id: string
  email: string
  name: string
  surname: string
  phone: string
  companyName: string
  totpTwoFaStatus: boolean
  emailTwoFaStatus: boolean
  backupPhrase: boolean
  emailConfirmed: boolean
  roles: string[]
  isActive: boolean
  createdAt: string
  metamask: {}
  phoneConfirmed: boolean
  phoneTwoFaStatus: boolean
  isUnsubscribeMail: boolean
  refId: string
}
export interface ITwoFactor {
  url: string
  secret: string
}

export interface IProfileBody {
  email?: string
  name?: string
  surname?: string
  companyName?: string
  companyUrl?: string
  phone?: string
  country?: string
  city?: string
  address?: string
}
export interface ITwoFactorBody {
  twoFactorToken: string
}
export interface IEmailResendCodeBody {
  email?: string
}
export interface IEmailTwoFaBody {
  code?: string
}
export interface IMultiFaBody {
  twoFactorToken: string
  code: string
}
export interface IMultiFaPrivateKeyBody {
  twoFactorToken?: string
  emailOtp?: string
}
export interface SettingsState {
  profile: IProfile | null
  twoFactor: ITwoFactor | null
  newEmail: string
}
export interface ISettingsContext {
  state: SettingsState
  getProfile: () => Promise<any>
  updateProfile: (body: IProfileBody) => Promise<any>
  verifyChangeEmail: (secretKey: string) => Promise<any>
  getTwoFactor: () => Promise<any>
  resetTwoFactor: () => Promise<any>
  activateTwoFactor: (body: ITwoFactorBody) => Promise<any>
  deactivateTwoFactor: (twoFactorToken: string) => Promise<any>
  emailTwoFaResendCode: (body: IEmailResendCodeBody) => Promise<any>
  activateEmailTwoFa: (body: IEmailTwoFaBody) => Promise<any>
  emailMultiFaResendCode: (body: IEmailResendCodeBody) => Promise<any>
}

export enum EActions {
  GET_PROFILE = 'GET_PROFILE',
  GET_TWO_FACTOR = 'GET_TWO_FACTOR',
  SET_NEW_EMAIL_ON_CHANGE = 'SET_NEW_EMAIL_ON_CHANGE'
}
export interface IProfileActionGet extends IReducerAction<EActions.GET_PROFILE> {
  profile: any
}
export interface ITwoFactorActionGet extends IReducerAction<EActions.GET_TWO_FACTOR> {
  twoFactor: any
}
export interface ISetEmailOnChange extends IReducerAction<EActions.SET_NEW_EMAIL_ON_CHANGE> {
  newEmail: any
}
export type Actions = IProfileActionGet | ITwoFactorActionGet | ISetEmailOnChange
