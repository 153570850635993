import { EStatus } from '@/constants/common/status'
import { Actions, EActions, ISwapHistoryState } from './types'

export const reducer = (state: ISwapHistoryState, action: Actions) => {
  switch (action.type) {
    case EActions.GET_HISTORY: {
      return {
        ...state,
        status: EStatus.PROCESSING
      }
    }
    case EActions.GET_HISTORY_SUCCESS: {
      return {
        ...state,
        transactions:
          action.pagination.page > 1
            ? [...state.transactions, ...action.transactions]
            : action.transactions,
        pagination: action.pagination,
        status: EStatus.SUCCESS
      }
    }
    case EActions.GET_HISTORY_ERROR: {
      return {
        ...state,
        status: EStatus.ERROR
      }
    }
    case EActions.SET_SELECTED_TRANSACTION: {
      return {
        ...state,
        selectedTransactionId: action.transactionId
      }
    }
  }
}
