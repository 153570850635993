import { IReducerAction } from '@/types/other/state.interface'

export enum EActions {
  GET_REFERRALS = 'GET_REFERRALS',
  UPDATE_WITHDRAW = 'UPDATE_WITHDRAW'
}

export interface IReferralsBody {
  refId: string
  earn: number
  refs: number
  minWithdrawal: number
  rewardPercent: number
}
export interface IWithdrawBody {
  estimationId: string
}
export interface IWithdrawEstimateBody {
  currency: string
  to: string
  amount: number
}

export interface ReferralsState {
  referrals: IReferralsBody
  withdraw: IWithdrawEstimateBody
}

export interface IReferralsContext {
  state: ReferralsState
  getReferrals: () => Promise<any>
  withdrawEstimate: (body: IWithdrawEstimateBody) => Promise<any>
  withdraw: (body: IWithdrawBody) => Promise<any>
  updateWithdraw: (body: IWithdrawEstimateBody) => void
}

export interface IReferralsActionGet extends IReducerAction<EActions.GET_REFERRALS> {
  referrals: IReferralsBody
}
export interface IWithdrawActionUpdate extends IReducerAction<EActions.UPDATE_WITHDRAW> {
  withdraw: IWithdrawEstimateBody
}

export type Actions = IReferralsActionGet | IWithdrawActionUpdate
