import { ComponentType, createContext, PropsWithChildren } from 'react'

import initialState from './state'
import { IImportContext } from './types'
import { useImportService } from './service'

export const ImportContext = createContext<IImportContext>({
  state: initialState(),
  updateState: () => {},
  importExternal: async () => {}
})

export const ImportProvider: ComponentType<PropsWithChildren> = ({ children }) => {
  const service = useImportService()
  return <ImportContext.Provider value={service}>{children}</ImportContext.Provider>
}
