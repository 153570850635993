import { Actions, BackUpState, EActions } from './types'

const reducer = (state: BackUpState, action: Actions): BackUpState => {
  switch (action.type) {
    case EActions.GET_SEED_PHRASE: {
      return {
        ...state,
        seedPhrase: action.seedPhrase
      }
    }
  }
}

export default reducer
