import { ComponentType, createContext, PropsWithChildren, useContext, useMemo } from 'react'
import { getHttpClient } from '@/http/client'

const HttpClientContext = createContext(getHttpClient())

export const HttpClientProvider: ComponentType<PropsWithChildren> = ({ children }) => {
  const httpClient = useMemo(() => getHttpClient(), [])

  return <HttpClientContext.Provider value={httpClient}>{children}</HttpClientContext.Provider>
}

const useHttpClient = () => useContext(HttpClientContext)

export default useHttpClient
