export enum ELanguagesCode {
  EN = 'en',
  RU = 'ru',
  ES = 'es',
  UA = 'uk'
}

export const LANGUAGES_MAP: Record<string, string> = {
  [ELanguagesCode.EN]: 'English',
  [ELanguagesCode.RU]: 'Russian',
  [ELanguagesCode.ES]: 'Español',
  [ELanguagesCode.UA]: 'Українська'
}

export const SUPPORTED_LANGUAGES = Object.keys(LANGUAGES_MAP)
