import { useReducer } from 'react'
import initialState from './state'
import { reducer } from './reducer'
import { EActions, IImportBody } from './types'
import useHttpClient from '../http-client'

export const useImportService = () => {
  const [state, dispatch] = useReducer(reducer, initialState())
  const httpClient = useHttpClient()

  const updateState = (body: IImportBody) => {
    dispatch({ type: EActions.UPDATE_IMPORT, import: body })
  }
  const importExternal = async (body: IImportBody) => {
    const { data } = await httpClient.post(`api/merchant/import/external`, body);
    return data;
  }

  return {
    state,
    updateState,
    importExternal
  }
}
