import { EStatus } from '@/constants/common/status'
import { ETransactionStatus } from '@/constants/transactions'
import { ISwapHistoryParams } from '@/types/api/swap/swap.interface'
import { IPagination } from '@/types/models/pagination.interface'
import { IReducerAction } from '@/types/other/state.interface'

export enum ESwapTransactionStatus {
  failure = 'failure',
  pending = 'pending',
  received = 'received'
}

export type ISwapTransactionModel = {
  fromCurrency: string
  toCurrency: string
  toAccount: string
  fromAccount: string
  networkFee: string
  networkName: string
  networkSymbol: string
  hash: string
  date: string
  status: ETransactionStatus
  partner: string
  exchangeRate: string
  sentAmount: string
  receivedAmount: string
  confirmationBlock: string
  id: string
  explorerUrl: string,
  networkFeeCurrency: string
  fromNetwork: string
  toNetwork: string
  fromCurrencyTitle: string
  toCurrencyTitle: string
}

export interface ISwapHistoryState {
  transactions: ISwapTransactionModel[]
  pagination: IPagination
  status: EStatus
  selectedTransactionId: string | null
}

export interface ISwapHistoryContext {
  state: ISwapHistoryState
  getHistory: (merchantId: string, params: ISwapHistoryParams) => Promise<any>
  setSelectedTransaction: (transactionId: string) => void
}

export enum EActions {
  GET_HISTORY = 'GET_HISTORY',
  GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS',
  GET_HISTORY_ERROR = 'GET_HISTORY_ERROR',
  SET_SELECTED_TRANSACTION = 'SET_SELECTED_TRANSACTION'
}

export interface IActionGetHistory extends IReducerAction<EActions.GET_HISTORY> {}

export interface IActionGetHistorySuccess extends IReducerAction<EActions.GET_HISTORY_SUCCESS> {
  transactions: ISwapTransactionModel[]
  pagination: IPagination
}

export interface IActionGetHistoryError extends IReducerAction<EActions.GET_HISTORY_ERROR> {}

export interface IActionSetSelectedTransaction
  extends IReducerAction<EActions.SET_SELECTED_TRANSACTION> {
    transactionId: string
  }

export type Actions =
  | IActionGetHistory
  | IActionGetHistorySuccess
  | IActionGetHistoryError
  | IActionSetSelectedTransaction
