import { IReducerAction } from '@/types/other/state.interface'

export enum EActions {
  UPDATE_IMPORT = 'UPDATE_IMPORT'
}

export interface IImportBody {
  key: string
  isMnemonic?: boolean
  name?: string
  currencies?: string[]
  onlyValidate?: boolean
}

export interface ImportState {
  import: IImportBody
}

export interface IImportContext {
  state: ImportState
  updateState: (body: IImportBody) => void
  importExternal: (body: IImportBody) => void
}

export interface INetworksActionGet extends IReducerAction<EActions.UPDATE_IMPORT> {
  import: IImportBody
}

export type Actions = INetworksActionGet
