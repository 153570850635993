import { IReducerAction } from '@/types/other/state.interface'

export interface INetwork {
  supportRecurringPayment: boolean;
  _id: string;
  name: string;
  blockchainName: string;
  title: string;
  nodeType: string;
  currencyType: string;
  replenishMinimumValue: number;
  decimals: number;
  supportSwap: boolean;
  supportMultisend: boolean;
}
export interface INetworksResponse {
  currencies:INetwork[],
  page:number,
  pages:number
}
export enum EActions {
  GET_NETWORKS = 'GET_NETWORKS',
  SET_SELECTED_NETWORKS = 'SET_SELECTED_NETWORKS'
}
export interface NetworksState {
  networks: INetwork[]
  selectedNetworks: string[]
}
export interface INetworkContext {
  state: NetworksState
  getNetworks: () => Promise<any>
  setSelectedNetworks: (selectedNetworks: string[]) => void
}
export interface INetworksActionGet extends IReducerAction<EActions.GET_NETWORKS> {
  networks: INetwork[]
}
export interface ISelectedNetworksActionSet extends IReducerAction<EActions.SET_SELECTED_NETWORKS> {
  selectedNetworks: string[]
}

export type Actions = INetworksActionGet | ISelectedNetworksActionSet
