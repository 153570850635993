import { useReducer } from 'react'
import initialState from './state'
import { reducer } from './reducer'
import { IApiResponse } from '@/types/api/api.interface'
import { EActions } from './types'
import { ISwapHistoryParams, ISwapHistoryResponse } from '@/types/api/swap/swap.interface'
import useHttpClient from '@/providers/http-client'
import { parseTransactions } from './parsers/parseTransactions'
import { IPagination } from '@/types/models/pagination.interface'

export const useSwapHistoryService = () => {
  const [state, dispatch] = useReducer(reducer, initialState())
  const httpClient = useHttpClient()

  const getHistory = async (merchantId: string, params: ISwapHistoryParams) => {
    dispatch({ type: EActions.GET_HISTORY })

    try {
      const { data } = await httpClient.get<IApiResponse<ISwapHistoryResponse>>(
        `api/swap/${merchantId}/history`,
        {
          params: {
            page: params.page,
            order: 'DESC'
          }
        }
      )

      const { entities, page, pages, countItems } = data.data

      const transactions = parseTransactions(entities)

      const pagination: IPagination = {
        page,
        pages,
        countItems
      }

      dispatch({ type: EActions.GET_HISTORY_SUCCESS, transactions, pagination })
    } catch {
      dispatch({ type: EActions.GET_HISTORY_ERROR })
    }
  }

  const setSelectedTransaction = (transactionId: string) => {
    dispatch({ type: EActions.SET_SELECTED_TRANSACTION, transactionId })
  }

  return {
    state,
    getHistory,
    setSelectedTransaction
  }
}
