import { useMemo, useState } from 'react'
import { useStorageContext } from '../storage/hooks/use-storage-context'
import { STORAGE_AUTH_KEY, STORAGE_AUTH_REFRESH_KEY } from '@/constants/auth/storage-auth-key'
import useHttpClient from '@/providers/http-client'

export const useAuthService = () => {
  const [tokenValue, setTokenValue] = useState<string | null>(null)
  const httpClient = useHttpClient()
  const storage = useStorageContext()

  const token = useMemo(() => {
    return tokenValue
  }, [tokenValue])

  const authorize = ({ token: newToken, refreshToken }: { token: string, refreshToken: string }) => {
    setTokenValue(newToken)
    storage.setItem(STORAGE_AUTH_KEY, newToken)
    storage.setItem(STORAGE_AUTH_REFRESH_KEY, refreshToken)
    httpClient.defaults.headers.Authorization = `Bearer ${newToken}`
  }

  return {
    token,
    authorize
  }
}
