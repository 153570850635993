import { Actions, EActions, ChangeEmailState } from './types'

export const reducer = (state: ChangeEmailState, action: Actions) => {
  switch (action.type) {
    case EActions.SET_NEW_EMAIL_ON_CHANGE: {
      return {
        ...state,
        newEmail: action.newEmail
      }
    }
  }
}
