import React, { ComponentType } from 'react'
import styles from './styles.module.scss'
import RedLoaderAnimation from '@/assets/lottie/red-loader.json'
import Lottie from 'lottie-react'
interface IProps {
  title: string
  info?: string
}
const Loader: ComponentType<IProps> = ({ title, info }) => {
  return (
    <div className={styles.loader}>
      <div className={styles.loaderWrapper}>
        <Lottie animationData={RedLoaderAnimation} loop={true} className={styles.loaderImage} />
        <h2 className={styles.title}>{title}</h2>
        {info && <span className={styles.info}>{info}</span>}
      </div>
    </div>
  )
}

export default Loader
