import { WithdrawalState } from './types'

const initialState = (): WithdrawalState => ({
  withdrawals: [],
  fee: null,
  estimateMax: '',
  sendUsdValue: '',
  sendCryptoValue: '',
  sendWalletAddress: '',
  sendScreenState: '',
  sendTonComment: '',
  sendTransactionSpeed: 'low',
  sendToggle: false
})

export default initialState
