import { ComponentType, createContext, PropsWithChildren, useContext } from 'react'

import { useSettingsService } from './service'
import {
  IChangeEmailContext,
  IMultiFaPrivateKeyBody
} from './types'
import initialState from './state'

const ChangeEmailContext = createContext<IChangeEmailContext>({
  state: initialState(),
  sendCodeToCurrentEmail: async () => {},
  sendCodeToNewEmail: async () => {},
  confirmCodeFromNewEmail: async (body: { twoFactorToken?: string }, code: string) => {},
  verifyCurrentEmailCode: async (code: string) => {},
  changeEmail: async (body: { email: string }) => {},
  updateEmailState: (email: string) => {},
  getUserPrivateKey: async (
    body: IMultiFaPrivateKeyBody,
    merchantId: string,
    walletId: string
  ) => {}
})

export const ChangeEmailProvider: ComponentType<PropsWithChildren> = ({ children }) => {
  const service = useSettingsService()
  return <ChangeEmailContext.Provider value={service}>{children}</ChangeEmailContext.Provider>
}

const useChangeEmailContext = () => useContext(ChangeEmailContext)

export default useChangeEmailContext
