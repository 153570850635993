import { ComponentType, createContext, PropsWithChildren } from 'react'

import { useNetworkService } from './service'
import { INetworkContext } from './types'
import initialState from './state'

export const NetworkContext = createContext<INetworkContext>({
  state: initialState(),
  getNetworks: async () => {},
  setSelectedNetworks: (selectedNetworks:any[]) => {}
})

export const NetworkProvider: ComponentType<PropsWithChildren> = ({ children }) => {
  const service = useNetworkService()
  return <NetworkContext.Provider value={service}>{children}</NetworkContext.Provider>
}
