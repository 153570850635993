import { useReducer } from 'react'
import initialState from './state'
import { reducer } from './reducer'
import { parseAssets } from './parsers/parseAssets'
import useHttpClient from '../http-client'
import { IApiResponse } from '@/types/api/api.interface'
import { IAssetBalanceItem } from '@/types/api/balance/assets.interface'
import { ICurrency } from '@/types/api/swap/currency.interface'
import {
  EActions,
  ISwapAssetModel,
  ISwapEstimateParams,
  ISwapOfferModel,
  ISwapParams
} from './types'
import { ISwapEsimateItem } from '@/types/api/swap/estimate.interface'
import { ISwapResponse } from '@/types/api/swap/swap.interface'
import { IEstimateMaxBody, IEstimateMaxResponse } from '@/types/api/withdrawal/withdrawal.interface'

const getEstimateControllers: AbortController[] = []

const rejectAllCurrentGetEstimateRequests = (): void => {
  getEstimateControllers?.forEach?.((controller) => {
    controller?.abort?.()
  })
  getEstimateControllers.length = 0
}

export const useSwapService = () => {
  const [state, dispatch] = useReducer(reducer, initialState())
  const httpClient = useHttpClient()

  const getAssets = async (assets: IAssetBalanceItem[], currencies: ICurrency[]): Promise<any> => {
    const swapAssets = parseAssets(assets, currencies)

    dispatch({ type: EActions.GET_ASSETS, assets: swapAssets })
  }

  const setAssetForSend = (asset: ISwapAssetModel) => {
    dispatch({ type: EActions.SET_ASSET_FOR_SEND, asset })
  }

  const setAssetForReceive = (asset: ISwapAssetModel) => {
    dispatch({ type: EActions.SET_ASSET_FOR_RECEIVE, asset })
  }

  const replaceAssets = () => {
    const forSend = state.assetForSend
    const forReceive = state.assetForReceive

    if (!forSend || !forReceive) return

    dispatch({
      type: EActions.SET_ASSET_FOR_SEND,
      asset: forReceive
    })

    dispatch({
      type: EActions.SET_ASSET_FOR_RECEIVE,
      asset: forSend
    })
  }

  const setSendAmount = (value: string) => {
    dispatch({
      type: EActions.SET_SEND_AMOUNT,
      sendAmount: value
    })
  }

  const estimateSwap = async () => {
    const assetForSend = state.assetForSend
    const assetForReceive = state.assetForReceive
    const sendAmount = state.sendAmount

    if (!assetForSend || !assetForReceive || !sendAmount || +sendAmount === 0) return

    rejectAllCurrentGetEstimateRequests()

    const controller = new AbortController()

    getEstimateControllers.push(controller)

    const params: ISwapEstimateParams = {
      fromId: assetForSend.id,
      toId: assetForReceive.id,
      amount: Number(sendAmount).toString(),
      sort: 'rate'
    }

    try {
      const { data } = await httpClient.get<IApiResponse<ISwapEsimateItem[]>>('api/swap/estimate', {
        params,
        signal: controller.signal
      })

      dispatch({
        type: EActions.SET_ESTIMATES,
        estimates: data.data.filter((offer) => offer.toAmount > 0)
      })
    } catch {}
  }

  const swap = async (body: ISwapParams) => {
    try {
      const { data } = await httpClient.post<IApiResponse<ISwapResponse>>('api/swap', body)

      dispatch({
        type: EActions.SET_SWAP_SUCCESS,
        swapData: data.data
      })
    } catch (error: any) {
      dispatch({
        type: EActions.SET_SWAP_ERROR,
        error
      })

      throw error
    }
  }

  const setOffer = (offer: ISwapOfferModel) => {
    dispatch({
      type: EActions.SET_OFFER,
      offer
    })
  }

  const estimateMax = async (
    { merchantId, walletId }: { merchantId: string; walletId: string },
    body: IEstimateMaxBody
  ) => {
    const { data } = await httpClient.post<IApiResponse<IEstimateMaxResponse>>(
      `api/withdrawal/${merchantId}/${walletId}/estimateMax`,
      body
    )

    return data.data
  }

  const resetState = () => {
    dispatch({ type: EActions.RESET_STATE })
  }

  return {
    state,
    getAssets,
    setAssetForSend,
    setAssetForReceive,
    replaceAssets,
    setSendAmount,
    estimateSwap,
    setOffer,
    swap,
    estimateMax,
    resetState
  }
}
